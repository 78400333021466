import PTM from '@/utilities/PTM';
import {
  HasProperty,
  ValidateCadastre,
  ValidateProjectId,
  ValidateProjectName,
} from '@/utilities/Validate';
import { PTMCategory } from '@/types/ptm';
import { GetAuthToken, BearerRequest, XhrRequest } from './xhr';

const API = String(process.env.VUE_APP_BACKEND_ENDPOINT);

export interface ProjectNote {
  id: string;
  created: number;
  content: string;
  author: string;
  cadastre?: string;
  icon?: string;
  position?: number[];
}

export interface Project {
  Author: string;
  Created: string;
  Name: string;
  Project: string;
  Status: string;
  Updated: number;
  Default: boolean;
}

export interface ProjectFile {
  id: string;
  created: number;
  updated: number;
  name: string;
  size: number;
  type: string;
  usedInMaps?: boolean;
}

export const GetProject = async (
  id: string
): Promise<Record<string, unknown>> => {
  const request = new BearerRequest(GetAuthToken);
  const encoded = encodeURIComponent(id);
  let error: Error | undefined;

  const result = await request
    .getJSON<Record<string, unknown>>(`${API}/project/${encoded}`)
    .catch((e) => {
      error = e;
    });

  if (!result || error) {
    return Promise.reject(error);
  }

  /* Remap some project keys */
  result.properties = {};
  const properties = result.properties as Record<
    string,
    Record<string, unknown>
  >;

  Object.keys(result)
    .filter((key) => HasProperty(result, key))
    .forEach((key) => {
      const needle = 'Cadastre#';
      const pos = key.indexOf(needle);

      if (pos === 0) {
        const cadastre = key.substring(needle.length);
        properties[cadastre] = {
          status: result[key],
        };
      }
    });

  return Promise.resolve(result);
};

export const GetProjects = async (owner = 'all'): Promise<Project[]> => {
  const request = new BearerRequest(GetAuthToken);
  let error: Error | undefined;
  const projects = await request
    .getJSON<Project[]>(`${API}/project?owner=${owner}`)
    .catch((e) => {
      error = e;
    });

  if (!projects || error) {
    return Promise.reject(error);
  }

  return projects;
};

export const CreateProject = async (name: string): Promise<string> => {
  if (ValidateProjectName(name)) {
    const request = new BearerRequest(GetAuthToken);
    let error: Error | undefined;

    const result = await request
      .postJSON(`${API}/project`, { name })
      .catch((e) => {
        error = e;
      });

    if (!result || error) {
      return Promise.reject(error);
    }

    if (Object.prototype.hasOwnProperty.call(result, 'id')) {
      PTM.event(PTMCategory.project, 'Create project', '');
      return Promise.resolve(String(result.id));
    }

    return Promise.reject(
      new Error(`Unknown reply from server ${String(result)}`)
    );
  }

  const badNameError = new Error('Ugyldig prosjektnavn');
  badNameError.name = 'InvalidParameterError';
  return Promise.reject(badNameError);
};

export const DeleteFile = async (
  projectId: string,
  fileId: string
): Promise<string> => {
  const request = new BearerRequest(GetAuthToken);
  let error: Error | undefined;

  const result = await request
    .deleteJSON(`${API}/upload`, {
      name: fileId,
      project: projectId,
    })
    .catch((e) => {
      error = e;
    });

  if (!result || error) {
    return Promise.reject(error);
  }
  PTM.event(PTMCategory.project, 'Delete file', '');

  return String(result.name);
};

export const DeleteProject = async (id: string): Promise<string> => {
  if (ValidateProjectId(id)) {
    const request = new BearerRequest(GetAuthToken);
    const encoded = encodeURIComponent(id);
    let error: Error | undefined;

    const result = await request
      .deleteJSON(`${API}/project/${encoded}`, {})
      .catch((e) => {
        error = e;
      });

    if (!result || error) {
      return Promise.reject(error);
    }

    if (Object.prototype.hasOwnProperty.call(result, 'id')) {
      PTM.event(PTMCategory.project, 'Delete project', '');

      return Promise.resolve(String(result.id));
    }

    return Promise.reject(
      new Error(`Unknown reply from server ${String(result)}`)
    );
  }

  const badIdError = new Error('Ugyldig prosjekt ID');
  badIdError.name = 'InvalidParameterError';
  return Promise.reject(badIdError);
};

export const DeleteCadastre = async (
  projectId: string,
  cadastre: string
): Promise<string> => {
  let badParamError: Error = new Error('Ukjent feil');

  if (ValidateProjectId(projectId)) {
    if (ValidateCadastre(cadastre)) {
      const request = new BearerRequest(GetAuthToken);
      const idEncoded = encodeURIComponent(projectId);
      const cadastreEncoded = encodeURIComponent(cadastre);
      let error: Error | undefined;

      const result = await request
        .deleteJSON(
          `${API}/project/${idEncoded}/cadastre/${cadastreEncoded}`,
          {}
        )
        .catch((e) => {
          error = e;
        });

      if (!result || error) {
        return Promise.reject(error);
      }

      PTM.event(PTMCategory.project, 'Remove cadastre from project', '');
      return Promise.resolve(String(result));
    }

    badParamError = new Error('Ugyldig matrikkelnummer');
  } else {
    badParamError = new Error('Ugyldig prosjekt-ID');
  }

  badParamError.name = 'InvalidParameterError';
  return Promise.reject(badParamError);
};

export const GetCadastres = async (
  projectId: string
): Promise<Record<string, string>> => {
  let badParamError: Error = new Error('Ukjent feil');

  if (ValidateProjectId(projectId)) {
    const request = new BearerRequest(GetAuthToken);
    const idEncoded = encodeURIComponent(projectId);
    let error: Error | undefined;

    const result = await request
      .getJSON<Record<string, string>>(
        `${API}/project/${idEncoded}/cadastre`,
        {}
      )
      .catch((e) => {
        error = e;
      });

    if (!result || error) {
      return Promise.reject(error);
    }

    return Promise.resolve(result);
  }

  badParamError = new Error('Ugyldig prosjekt-ID');
  badParamError.name = 'InvalidParameterError';

  return Promise.reject(badParamError);
};

export const SaveCadastre = async (
  projectId: string,
  cadastre: string
): Promise<string> => {
  let badParamError: Error = new Error('Ukjent feil');

  if (ValidateProjectId(projectId)) {
    if (ValidateCadastre(cadastre)) {
      const request = new BearerRequest(GetAuthToken);
      const idEncoded = encodeURIComponent(projectId);
      const cadastreEncoded = encodeURIComponent(cadastre);
      let error: Error | undefined;

      const result = await request
        .putJSON(`${API}/project/${idEncoded}/cadastre/${cadastreEncoded}`, {})
        .catch((e) => {
          error = e;
        });

      if (!result || error) {
        return Promise.reject(error);
      }
      PTM.event(PTMCategory.project, 'Add cadastre to project', '');
      return Promise.resolve(String(result));
    }

    badParamError = new Error('Ugyldig matrikkelnummer');
  } else {
    badParamError = new Error('Ugyldig prosjekt-ID');
  }

  badParamError.name = 'InvalidParameterError';
  return Promise.reject(badParamError);
};

export const SetProjectState = async (
  projectId: string,
  stateIdx: number
): Promise<string> => {
  const stateLabels = ['Aktiv', 'Avsluttet', 'Arkivert'];
  const request = new BearerRequest(GetAuthToken);
  const idEncoded = encodeURIComponent(projectId);
  const stateEncoded = encodeURIComponent(stateIdx);
  let error: Error | undefined;

  const result = await request
    .patchJSON(`${API}/project/${idEncoded}/status/${stateEncoded}`, {})
    .catch((e) => {
      error = e;
    });

  if (!result || error) {
    return Promise.reject(error);
  }

  PTM.event(
    PTMCategory.project,
    'Set project status',
    stateLabels[Number(stateEncoded)]
  );

  return Promise.resolve(String(result));
};

export const SetProjectName = async (
  projectId: string,
  name: string
): Promise<string> => {
  const request = new BearerRequest(GetAuthToken);
  const idEncoded = encodeURIComponent(projectId);
  let error: Error | undefined;

  const result = await request
    .patchJSON(`${API}/project/${idEncoded}/name`, {
      name,
    })
    .catch((e) => {
      error = e;
    });

  if (!result || error) {
    return Promise.reject(error);
  }

  PTM.event(PTMCategory.project, 'Rename project', '');

  return Promise.resolve(String(result));
};

export const GetFile = async (
  projectId: string,
  fileId = ''
): Promise<ProjectFile> => {
  const request = new BearerRequest(GetAuthToken);
  const projectIdEncoded = encodeURIComponent(projectId);
  const fileIdEncoded = encodeURIComponent(fileId);
  let error: Error | undefined;

  const result = await request
    .getJSON<ProjectFile>(`${API}/files/${projectIdEncoded}/${fileIdEncoded}`)
    .catch((e) => {
      error = e;
    });

  if (!result || error) {
    return Promise.reject(error);
  }

  return result;
};

export const GetFiles = async (
  projectId: string,
  type = ''
): Promise<ProjectFile[]> => {
  const request = new BearerRequest(GetAuthToken);
  const idEncoded = encodeURIComponent(projectId);
  let typeEncoded = encodeURIComponent(type);
  let error: Error | undefined;

  if (typeEncoded.length) {
    typeEncoded = `/${typeEncoded}`;
  }

  const result = await request
    .getJSON<ProjectFile[]>(`${API}/files/${idEncoded}${typeEncoded}`)
    .catch((e) => {
      error = e;
    });

  if (!result || error) {
    return Promise.reject(error);
  }

  return result;
};

export const GetFileDownloadLink = async (
  projectId: string,
  fileId: string
): Promise<string> => {
  if (ValidateProjectId(projectId)) {
    const request = new BearerRequest(GetAuthToken);
    const idEncoded = encodeURIComponent(projectId);
    const fileEncoded = encodeURIComponent(fileId);
    let error: Error | undefined;

    const result = await request
      .getJSON<Record<string, string>>(
        `${API}/file/${idEncoded}/${fileEncoded}`,
        {}
      )
      .catch((e) => {
        error = e;
      });

    if (!result || error) {
      return Promise.reject(error);
    }

    return Promise.resolve(result.url);
  }

  const badParamError = new Error('Ugyldig prosjekt-ID');
  badParamError.name = 'InvalidParameterError';

  return Promise.reject(badParamError);
};

export const UploadFile = async (
  projectId: string,
  file: File,
  progressCb?: (loaded: number, total: number) => void
): Promise<Record<string, string>> => {
  const request = new BearerRequest(GetAuthToken);
  let error: Error | undefined;

  const uploadURL = (await request
    .postJSON(`${API}/upload`, {
      name: file.name,
      size: file.size,
      project: projectId,
    })
    .catch((e) => {
      error = e;
    })) as Record<string, string>;

  if (!uploadURL || error) {
    return Promise.reject(error);
  }

  const { url, name } = uploadURL;
  const uploadRequest = new XhrRequest();

  if (progressCb) {
    uploadRequest.subscribe('progress', (event) => {
      if (event.lengthComputable) {
        progressCb(event.loaded, event.total);
      }
    });
  }

  const upload = await uploadRequest
    .put(url, file, {
      'Content-Type': file.type,
    })
    .catch((e: Error) => {
      error = e;
    });

  if (!upload || error) {
    return Promise.reject(error);
  }

  PTM.event(
    PTMCategory.project,
    'Upload file',
    file.name.split('.').pop() || 'unknown filetype',
    file.size
  );

  return Promise.resolve({ id: name });
};

export const SaveNote = async (projectId: string, note: ProjectNote) => {
  if (note.content.length && ValidateProjectId(projectId)) {
    if (!note.cadastre || ValidateCadastre(note.cadastre)) {
      const request = new BearerRequest(GetAuthToken);
      let error: Error | undefined;

      const pId = encodeURIComponent(projectId);
      const body: Record<string, string> = {
        content: note.content,
      };

      if (note.cadastre) {
        body.cadastre = note.cadastre;
      }

      const created = await request
        .putJSON(`${API}/project/${pId}/note`, body)
        .catch((e) => {
          error = e;
        });

      if (!created || error) {
        return Promise.reject(error);
      }
      PTM.event(PTMCategory.project, 'Save note', '');

      return created;
    }
  }

  return Promise.reject(new Error('Invalid parameters'));
};

export const DeleteNote = async (projectId: string, noteId: string) => {
  if (ValidateProjectId(projectId)) {
    const regex = /^[a-f0-9-]{36}_\d{1,2000}$/;

    if (regex.test(noteId)) {
      const request = new BearerRequest(GetAuthToken);
      let error: Error | undefined;

      const pId = encodeURIComponent(projectId);
      const nId = encodeURIComponent(noteId);

      const deleted = await request
        .deleteJSON(`${API}/project/${pId}/note/${nId}`, {})
        .catch((e) => {
          error = e;
        });

      if (!deleted || error) {
        return Promise.reject(error);
      }
      PTM.event(PTMCategory.project, 'Delete note', '');

      return deleted;
    }
  }

  return Promise.reject(new Error('Invalid parameters'));
};

export const GetNotes = async (projectId: string, idOrCadastre?: string) => {
  let valid = ValidateProjectId(projectId);
  let idStr = '';

  if (valid && idOrCadastre) {
    const regex = /^[a-f0-9-]{36}_\d{1,2000}$/;
    valid &&= ValidateCadastre(idOrCadastre) || regex.test(idOrCadastre);
    idStr = `/${encodeURIComponent(idOrCadastre)}`;
  }

  if (valid) {
    const request = new BearerRequest(GetAuthToken);
    let error: Error | undefined;

    const pId = encodeURIComponent(projectId);

    const notes = await request
      .getJSON(`${API}/project/${pId}/note${idStr}`)
      .catch((e) => {
        error = e;
      });

    if (!notes || error) {
      return Promise.reject(error);
    }

    return notes;
  }

  return Promise.reject(new Error('Invalid parameters'));
};

export const GetEvents = async (
  projectId: string
): Promise<Record<string, unknown>[]> => {
  if (ValidateProjectId(projectId)) {
    const request = new BearerRequest(GetAuthToken);
    let error: Error | undefined;

    const pId = encodeURIComponent(projectId);

    const events = await request
      .getJSON<Record<string, unknown>[]>(`${API}/project/${pId}/events`)
      .catch((e) => {
        error = e;
      });

    if (!events || error) {
      return Promise.reject(error);
    }

    return events;
  }

  return Promise.reject(new Error('Invalid parameters'));
};

export default {
  CreateProject,
  DeleteFile,
  DeleteCadastre,
  DeleteProject,
  GetCadastres,
  GetFiles,
  GetProject,
  GetProjects,
  SaveCadastre,
  SaveNote,
  UploadFile,
};
